<template>
  <div class="serviceDetail">
    <div class="tabar">
      <span>您所在的位置：&nbsp;&nbsp;</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/portal/serviceHall' }"
          >服务大厅</el-breadcrumb-item
        >
        <el-breadcrumb-item>服务详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="Content">
      <div class="picContent">
        <div class="messageBox">
          <div class="picBox">
            <img :src="picList[picIndex]" alt="" />
          </div>
          <div class="messageContent">
            <div class="m-title">{{ dateObj.title }}</div>
            <div class="m-text">
              <div class="text1">服务价格</div>
              <span
                v-if="dateObj.budgetType !== 1"
                style="font-size: 26px; color: #17a763"
                >{{ dateObj.price }}</span
              >
              <span v-if="dateObj.budgetType !== 1">{{
                dateObj.unitName
              }}</span>
              <span
                style="font-size: 26px; color: #17a763"
                v-if="dateObj.budgetType === 1"
                >面议</span
              >
            </div>
            <div class="m-text">
              <div class="text1">服务品种</div>
              <span>{{ dateObj.serviceVarietyName }}</span>
            </div>
            <div class="m-text">
              <div class="text1">服务类型</div>
              <span>{{ dateObj.serviceTypeName }}</span>
            </div>
            <div class="m-text">
              <div class="text1">服务时间</div>
              <span>{{
                (dateObj.startTime || "") + " 至 " + (dateObj.endTime || "")
              }}</span>
            </div>
            <div class="m-text">
              <div class="text1">服务区域</div>
              <span>{{ optArea(dateObj) || "暂无" }}</span>
            </div>
            <div class="m-text">
              <div class="text1">服务主体</div>
              <span>{{ organization.companyName }}</span>
            </div>
            <div class="m-text">
              <div class="text1">距离结束</div>
              <span>{{
                dateObj.finishDay > 0 ? dateObj.finishDay : "已截止"
              }}</span>
            </div>
            <div class="m-text">
              <div class="text1">浏览次数</div>
              <span>{{ dateObj.viewTotal }}</span>
            </div>
          </div>
        </div>
        <div class="picList">
          <div class="picLittContent" v-if="picList.length > 0">
            <i class="el-icon-arrow-left" @click="reduceIndex"></i>
            <img
              @click="handleImg(index)"
              v-for="(item, index) in picList"
              :key="index"
              :src="item"
            />
            <i class="el-icon-arrow-right" @click="addIndex"></i>
          </div>
          <div class="tokenContent">
            <div class="btnContent" @click="contactMe">
              <img src="../../assets/image/phone.png" alt="" />
              <span>立即联系</span>
            </div>
            <!-- <div style="background-color: #17a763" class="btnContent">
              <img src="../../assets/image/car.png" alt="" />
              <span style="color: #fff">立即下单</span>
            </div> -->
          </div>
        </div>
        <div class="searvContent">
          <div class="sear-title">服务详情</div>
          <div class="servBox">
            <div style="border-bottom: 1px dashed #dddddd" class="left-box">
              <div class="ser-pic">
                <img src="../../assets/image/file.png" alt="" />
                <div>服务标准</div>
              </div>
              <div class="sp-text" v-html="optDetail()"></div>
            </div>
            <div class="left-box">
              <div class="ser-pic">
                <img src="../../assets/image/pic.png" alt="" />
                <div>服务图片</div>
              </div>
              <div class="serBoxContent">
                <img
                  v-for="(item, index) in picList"
                  :key="index"
                  :src="item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightContent">
        <div class="housContent">
          <img src="../../assets/image/house.png" alt="" />
          <div class="text1">
            {{ organization && organization.companyName }}
          </div>
          <!-- <el-button @click="goAgriculate">服务商主页</el-button> -->
          <div v-if="optArea(organization)" class="text2">
            <!-- <i id="text4" class="el-icon-location-outline"></i> -->
            <div class="text3">{{ optArea(organization) }}</div>
          </div>
        </div>
        <div class="hotServe">
          <div class="line-box">
            <div class="hs-title">热门服务</div>
          </div>
          <div class="imgBox">
            <div
              v-for="(item, index) in hotServiceRelease"
              :key="index"
              class="img-box"
            >
              <img :src="item.imgUrl" alt="" />
              <div
                @click="changeClick(item)"
                style="
                  margin-bottom: 15px;
                  width: 280px;
                  word-break: break-all;
                  cursor: pointer;
                "
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import index from "./index";
import { selectServiceReleaseById } from "@/api/serviechall";
import { getToken } from "@/utils/auth";

import { Base64 } from "js-base64";
export default {
  components: {
    index,
  },
  data() {
    return {
      picList: [],
      dateObj: {},
      picIndex: 0,
      organization: {},
      hotServiceRelease: [],
    };
  },
  methods: {
    changeStr(index) {
      this.str = this.array[index];
    },
    addIndex() {
      let length = this.picList.length;
      this.picIndex = (this.picIndex + 1) % length;
    },
    contactMe() {
      if (!getToken()) {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
        return;
      }
      if (this.dateObj.finishDay > 0) {
        this.$confirm(`联系电话： ${this.dateObj.phone}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {})
          .catch(() => {});
      } else {
        this.$message.info("该服务已结束");
      }
    },
    reduceIndex() {
      let length = this.picList.length;
      this.picIndex = this.picIndex == 0 ? length : this.picIndex;
      this.picIndex = (this.picIndex - 1) % length;
    },
    handleImg(item) {
      this.picIndex = item;
    },
    optArea(item) {
      if (!item) {
        return false;
      }
      const { province, city, county, town, village } = item;
      if (!province && !city && !county && !town && !village) {
        return false;
      }
      return (
        (province || "") +
        (city || "") +
        (county || "") +
        (town || "") +
        (village || "")
      );
    },
    goAgriculate() {
      if (this.organization && this.organization.id) {
        this.$router.push(
          `/portal/organizationDetail?id=${this.organization.id}`
        );
      }
    },
    optDetail() {
      let detail = this.dateObj.detail;
      if (!detail) {
        return;
      }
      if (this.isBase64(detail)) {
        detail = escape(window.atob(detail));
        try {
          detail = decodeURIComponent(detail);
        } catch (error) {}
      }
      return detail;
    },
    isInclude(URL) {
      // return URL
      if (URL.indexOf("%") > -1) {
        return URL.replace(/%/g, "%25");
      } else {
        return URL;
      }
    },
    isBase64(str) {
      str = String(str);
      if (str === "" || str.trim() === "") {
        return false;
      }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
      return true;
    },
    changeClick(item) {
      let query = { id: item.id };
      this.selectServiceReleaseById(query);
    },
    selectServiceReleaseById(query) {
      selectServiceReleaseById(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const { serviceRelease, organization, hotServiceRelease } = data;
          this.dateObj = serviceRelease;
          this.organization = organization;
          if (hotServiceRelease) {
            this.hotServiceRelease = hotServiceRelease.splice(0, 7);
          }
          if (this.dateObj.imgUrl) {
            let list = this.dateObj.imgUrl.split(",");
            this.picList = list.splice(0, 4);
          }
        }
      });
    },
  },
  created() {
    let query = { id: this.$route.query.id };
    this.selectServiceReleaseById(query);
  },
};
</script>
<style lang="scss" scoped>
.serviceDetail {
  width: 100%;
  .tabar {
    height: 52px;
    width: 1396px;
    margin: 0 auto 20px;
    color: #555;
    font-size: 12px;
    display: flex;
    align-items: center;
    /deep/ .el-breadcrumb {
      font-size: 12px;
      color: #555;
    }
  }
  .Content {
    width: 1396px;
    margin: 0 auto;
    padding-left: 20px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .picContent {
      width: 1046px;
      .messageBox {
        height: 454px;
        display: flex;
        .picBox {
          width: 454px;
          height: 454px;
          border: 1px solid #dddddd;
          display: flex;
          align-items: center;
          img {
            width: 454px;
            height: 300px;
          }
        }
        .messageContent {
          width: 570px;
          margin-left: 20px;
          border-bottom: 1px solid #dddddd;
          .m-title {
            font-size: 20px;
            color: #000;
            font-weight: bold;
            height: 44px;
            border-bottom: 1px solid #dddddd;
          }
          .m-text {
            display: flex;
            align-items: center;
            padding: 10px 0px;
            font-size: 14px;
            color: #333;
            .text1 {
              width: 100px;
            }
          }
        }
      }
      .picList {
        width: 100%;
        display: flex;
        padding-left: 10px;
        font-size: 14px;
        color: #999999;
        align-items: center;
        margin-top: 15px;
        .picLittContent {
          display: flex;
          align-items: center;
          width: 455px;
          overflow: hidden;
        }
        .tokenContent {
          display: flex;
          align-items: center;
          .btnContent {
            height: 54px;
            font-size: 18px;
            color: #17a763;
            border-radius: 10px;
            border: 1px solid #17a763;
            margin-right: 17px;
            display: flex;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
            cursor: pointer;
            img {
              width: 28px;
              height: 30px;
            }
          }
        }
        img {
          width: 96px;
          height: 96px;
          margin-right: 8px;
        }
      }
      .searvContent {
        margin-top: 60px;
        .sear-title {
          width: 190px;
          text-align: center;
          border: 1px solid #dddddd;
          border-bottom: none;
          border-radius: 5px;
          background-color: #f8f8f8;
          height: 59px;
          line-height: 59px;
          font-size: 18px;
          color: #333333;
          cursor: pointer;
        }
        .servBox {
          padding: 0 20px;
          border: 1px solid #dddddd;
          .left-box {
            padding: 20px 0px;
            display: flex;
            text-align: center;
            .ser-pic {
              margin-right: 20px;
            }
            .sp-text {
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              word-break: break-all;
            }
            img {
              width: 56px;
              height: 62px;
            }
            .serBoxContent {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              img {
                width: 210px;
                height: 155px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .rightContent {
      width: 280px;
      .housContent {
        padding: 22px;
        background-color: #f9f9f9;
        border: 1px solid #dddddd;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        .text1 {
          margin-bottom: 10px;
          text-align: center;
        }
        .text2 {
          text-align: start;
          margin-top: 4px;
          padding-left: 8px;
          display: flex;
          .text3 {
            font-size: 14px;
            font-weight: 500;
            color: #333;
            margin-left: 10px;
            margin-bottom: 4px;
            word-break: break-all;
            width: 200px;
            line-height: 20px;
          }
          #text4 {
            line-height: 20px;
            font-weight: bold;
            font-size: 14px;
          }
        }
        color: #333;
        img {
          width: 225px;
          height: 113px;
          margin-bottom: 10px;
        }
        /deep/ .el-button {
          width: 218px;
          height: 46px;
          background-color: #17a763;
          border-radius: 5px;
          text-align: center;
          font-size: 18px;
          color: #fff;
        }
      }
      .hotServe {
        width: 280px;
        .line-box {
          width: 100%;
          border-bottom: 2.5px solid #dddddd;
          height: 34px;
        }
        .hs-title {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          height: 36px;
          line-height: 39px;
          padding-left: 15px;
          width: 80px;
          box-sizing: border-box;
          border-bottom: 3.5px solid #17a763;
        }
        .imgBox {
          width: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 100%;
          img {
            width: 213px;
            height: 141px;
          }
          .img-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>